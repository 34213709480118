import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { selectData } from '../pages/homeSlice';
import { Element } from 'react-scroll';
// Data
import { moreInfo_1, moreInfo_2, moreInfo_3 } from '../data';
// Components
import { Col, Container, Row } from 'react-bootstrap';
import { Title } from './globalStyledComponents';

const StyledAboutMe = styled.section`
	p {
		font-size: 1.25rem;
	}
	.img {
		width: 18rem;
		height: 18rem;
	}
`;

export default function AboutMe() {
	const { avatar_url } = useSelector(selectData);

	return (
		<Element name={'About'} id='about'>
			<StyledAboutMe className='section'>
				<Container>
					<Container className='d-flex pt-4'>
						<Title>
							<h2>About Me</h2>
							<div className='underline'></div>
						</Title>
					</Container>
					<Row className='align-items-center mt-4'>
						<Col className='d-flex flex-column text-center'>
							<Container>
								{/* <p>{bio}</p> */}
								<p className='mt-5'>{moreInfo_1}</p>
								<p className='mt-5'>{moreInfo_2}</p>
								<p className='mt-5'>{moreInfo_3}</p>
							</Container>
						</Col>
						<Col className='d-none d-md-block text-center'>
							<img src={avatar_url} alt='GitHub Avatar' loading='lazy' className='mx-auto rounded-circle' style={{ width: '15rem', height: '15rem' }} />
						</Col>
					</Row>
					<Row className='align-items-center mt-5'>
						<Col className='d-block d-md-none text-center'>
							<img src={avatar_url} alt='GitHub Avatar' loading='lazy' className='mx-auto rounded-circle' style={{ width: '20rem', height: '20rem' }} />
						</Col>
					</Row>
				</Container>
			</StyledAboutMe>
		</Element>
	);
}

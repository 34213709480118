import React from 'react';
import { Element } from 'react-scroll';
// Data
import { offeringsData } from '../data';
// Components
import { Col, Container, Row } from 'react-bootstrap';
import { Title } from './globalStyledComponents';

export default function Offerings() {
	return (
		<Element name={'Offerings'} id='offerings'>
			<section className='section'>
				<Container className='text-center  pt-4'>
					<Title>
						<h2>Mechanical Design</h2>
						<div className='underline'></div>
					</Title>
					<Row className='d-flex justify-content-around align-items-start pt-4'>
						{offeringsData.map((offerings) => {
							return (
								<Col xs={5} key={offerings.id} className='px-2 mb-5'>
									<figure>
										{offerings.offerIcon}
										<figcaption className='mt-1'>
											<strong>{offerings.title}</strong>
										</figcaption>
										<figcaption className='mt-4'>{offerings.text}</figcaption>
									</figure>
								</Col>
							);
						})}
					</Row>
				</Container>
			</section>
		</Element>
	);
}

import React from 'react';
import { useSelector } from 'react-redux';
import { selectData } from '../pages/homeSlice';
import { Link } from 'react-scroll';
import styled from 'styled-components';
// Icons
import { FaChevronCircleDown } from 'react-icons/fa';
// Media
import { Light, Dark } from '../data';
// Components
import { Col, Container, Row } from 'react-bootstrap';
import { Spin } from './globalStyledComponents';
import SocialLinks from './SocialLinks';

const StyledHero = styled.header`
	position: relative;
	display: grid;
	place-items: center;
	max-width: 1920px;
	margin: 0 auto;
	min-height: calc(100vh - var(--nav-height));

	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: ${({ theme }) => (theme.name === 'light' ? 'linear-gradient(135deg, var(--primary), var(--bs-light))' : 'linear-gradient(135deg, var(--primary), var(--bs-dark))')};
		z-index: -2;
	}

	/* Overlay for contrast */
	&::after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: ${({ theme }) => (theme.name === 'light' ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.2)')};
		z-index: -1;
	}

	.down-container {
		height: 10rem;
	}

	@media (prefers-reduced-motion: no-preference) {
		.hero-img {
			animation: ${Spin} infinite 20s linear;
		}
	}

	@media screen and (min-width: 1100px) {
		&::before {
			background: ${({ theme }) => (theme.name === 'light' ? `url(${Light}) top center fixed no-repeat` : `url(${Dark}) top center fixed no-repeat`)};
			background-size: 100vw auto;
		}
	}

	@media screen and (min-width: 1367px) {
		&::before {
			background: ${({ theme }) => (theme.name === 'light' ? `url(${Light}) center center fixed no-repeat` : `url(${Dark}) center center fixed no-repeat`)};
			background-size: cover;
		}
	}
`;

export default function Hero() {
	const { name } = useSelector(selectData);

	return (
		<StyledHero>
			<Container>
				<Row className='align-items-center text-center'>
					<Col>
						<h1 className='mt-1 mb-1 display-3 title'>{name}</h1>
						<h1 className='mb-3 display-6'>Senior Mechanical Engineer, Full-Stack Software Engineer, and Good Human</h1>
						<div className='d-flex py-4 align-items-center justify-content-center'>
							<SocialLinks />
						</div>
					</Col>
				</Row>
				<Row className='align-items-end down-container'>
					<Col className='m-4 text-center'>
						<Link to={'About'} className='link-icons' smooth={true} duration={500}>
							<FaChevronCircleDown />
						</Link>
					</Col>
				</Row>
			</Container>
		</StyledHero>
	);
}

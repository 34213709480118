import React from 'react';
import styled from 'styled-components';
// Icons
import { FaGithub, FaHome } from 'react-icons/fa';
// Media
import GH from '../images/Octocat.jpg';
// Components
import { Card } from 'react-bootstrap';
import PropTypes from 'prop-types';

const StyledCardComponent = styled.div`
	.card-footer {
		border-top: var(--border);

		.card-link {
			text-decoration: none;
			font-size: 2rem;
			color: ${({ theme }) => theme.color};

			&:hover {
				color: var(--primary);
			}
		}
	}
`;

export default function StyledCard({ theme, image, name, description, url, homepage }) {
	return (
		<StyledCardComponent>
			<Card bg={theme === 'light' ? 'white' : 'dark'}>
				<Card.Img variant='top' src={image ? image : GH} alt={name} className='mx-auto mt-3' />
				<Card.Body className='overflow-auto text-center'>
					<Card.Title>{name}</Card.Title>
					<Card.Text>{description}</Card.Text>
				</Card.Body>
				<Card.Footer className='text-center'>
					<Card.Link href={url} target='_blank' rel='noopener noreferrer'>
						<FaGithub />
					</Card.Link>
					{typeof homepage !== 'undefined' && homepage !== null && homepage !== '' && (
						<Card.Link href={homepage} target='_blank' rel='noopener noreferrer'>
							<FaHome />
						</Card.Link>
					)}
				</Card.Footer>
			</Card>
		</StyledCardComponent>
	);
}

StyledCard.propTypes = {
	theme: PropTypes.string.isRequired,
	image: PropTypes.string,
	name: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
	url: PropTypes.string.isRequired,
	homepage: PropTypes.string,
};

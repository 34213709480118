import React from 'react';
import { useAppContext } from '../appContext';
import { Element } from 'react-scroll';
// Data
import { skillData, resume } from '../data';
// Components
import { Button, Col, Container, Row } from 'react-bootstrap';
import { Title } from './globalStyledComponents';

export default function Skills() {
	const { theme } = useAppContext();

	return (
		<Element name={'Skills'} id='skills'>
			<section className='section'>
				<Container className='text-center  pt-4'>
					<Title>
						<h2>Software Skills</h2>
						<div className='underline'></div>
					</Title>
					<Row className='mt-3 align-items-center'>
						{skillData.map((skills) => {
							return (
								<Col md={2} xs={4} key={skills.id} className='my-3'>
									<figure>
										{skills.skill}
										<figcaption>{skills.name}</figcaption>
									</figure>
								</Col>
							);
						})}
					</Row>
					{resume && (
						<a href={resume}>
							<Button size='lg' variant={theme === 'light' ? 'outline-dark' : 'outline-light'} className='mt-5'>
								R&eacute;sum&eacute;
							</Button>
						</a>
					)}
				</Container>
			</section>
		</Element>
	);
}

import React from 'react';
// Skills icons - https://react-icons.github.io/react-icons/
import { FaHtml5, FaCss3Alt, FaBootstrap, FaReact, FaGitAlt, FaGithubSquare, FaAws, FaDatabase, FaNode, FaUnity, FaGoogle, FaLinux, FaDocker } from 'react-icons/fa';
import { BsWrenchAdjustable } from 'react-icons/bs';
import { PiCircuitry } from 'react-icons/pi';
import { FiDatabase } from 'react-icons/fi';
import { GiPencilBrush } from 'react-icons/gi';
import { SiJavascript, SiTailwindcss, SiCplusplus, SiCsharp, SiMicrosoftazure } from 'react-icons/si';

// Projects Images (add your images to the images directory and import below)
import hotDog from './images/hotDog.png';
import bugEye from './images/bugEye.png';
import conwaysGame from './images/gameOfLife.jpg';
import jamesOfReno from './images/jamesOfReno.png';
import pinonTree from './images/pinonTrees.png';

// Hero Images (add your images to the /images directory with the same names)
import HeroLight from './images/hero-light.jpg';
import HeroDark from './images/hero-dark.jpg';
// If you change the import names above then you need to change the export names below
export { HeroLight as Light };
export { HeroDark as Dark };

/* START HERE
 **************************************************************
  Add your GitHub username (string - "YourUsername") below.
*/
export const githubUsername = 'chadvidovcich';

/* About Me
 **************************************************************
  Add a second paragraph for the about me section.
*/
export const moreInfo_1 = "Hi, I'm Chad! 👋";
export const moreInfo_2 =
	'I’m a dynamic professional wielding expertise in both mechanical engineering and software development, passionate about crafting cutting-edge product designs. My playground is the intersection of hardware and software, where I develop creative integrated solutions that respond quickly to market needs.';
export const moreInfo_3 =
	'I’m all about bringing inventive energy to every project. Committed to being a good human, I prioritize ethical practices and positive impacts in all my endeavors. As a lifelong learner, I continuously seek new knowledge and skills, adapting to the latest trends and technologies. I thrive on collaborating with cross-disciplinary teams, believing that diverse perspectives fuel innovation and lead to more effective solutions.';

/* Skills
 ************************************************************** 
  Add or remove skills in the SAME format below, there must be one icon imported above per skill below.
*/
export const offeringsData = [
	{
		id: 1,
		offerIcon: <PiCircuitry className='display-4' />,
		title: 'Electromechanical Design',
		text: 'Adept at integrating mechanical systems with electronic components to optimize performance and efficiency.',
	},
	{
		id: 2,
		offerIcon: <BsWrenchAdjustable className='display-4' />,
		title: 'Troubleshooting and Diagnostics',
		text: 'Diagnosing and resolving complex system problems.',
	},
	{
		id: 3,
		offerIcon: <FiDatabase className='display-4' />,
		title: 'Databases',
		text: 'Management, improvement, and team training on database systems, including PLM, to enhance data storage and lifecycle management capabilities.',
	},
	{
		id: 4,
		offerIcon: <GiPencilBrush className='display-4' />,
		title: '3D Modeling',
		text: 'Advanced CAD ability, specifically in SolidWorks, enabling precise and complex design creations.',
	},
];

/* Skills
 ************************************************************** 
  Add or remove skills in the SAME format below, there must be one icon imported above per skill below.
*/
export const skillData = [
	{
		id: 1,
		skill: <FaHtml5 className='display-4' />,
		name: 'HTML5',
	},
	{
		id: 2,
		skill: <FaCss3Alt className='display-4' />,
		name: 'CSS3',
	},
	{
		id: 3,
		skill: <SiJavascript className='display-4' />,
		name: 'JavaScript',
	},
	{
		id: 4,
		skill: <FaBootstrap className='display-4' />,
		name: 'BootStrap',
	},
	{
		id: 5,
		skill: <FaReact className='display-4' />,
		name: 'React',
	},
	{
		id: 6,
		skill: <SiTailwindcss className='display-4' />,
		name: 'Tailwind CSS',
	},
	{
		id: 7,
		skill: <FaNode className='display-4' />,
		name: 'Node.js',
	},
	{
		id: 8,
		skill: <FaGitAlt className='display-4' />,
		name: 'Git',
	},
	{
		id: 9,
		skill: <FaGithubSquare className='display-4' />,
		name: 'GitHub',
	},
	{
		id: 10,
		skill: <FaAws className='display-4' />,
		name: 'Amazon AWS',
	},
	{
		id: 11,
		skill: <FaGoogle className='display-4' />,
		name: 'Google Cloud Platform',
	},
	{
		id: 12,
		skill: <SiMicrosoftazure className='display-4' />,
		name: 'Azure',
	},
	{
		id: 13,
		skill: <FaDatabase className='display-4' />,
		name: 'Databases',
	},
	{
		id: 14,
		skill: <FaUnity className='display-4' />,
		name: 'Unity',
	},
	{
		id: 15,
		skill: <FaLinux className='display-4' />,
		name: 'Linux',
	},
	{
		id: 16,
		skill: <FaDocker className='display-4' />,
		name: 'Docker',
	},
	{
		id: 17,
		skill: <SiCplusplus className='display-4' />,
		name: 'C++',
	},
	{
		id: 18,
		skill: <SiCsharp className='display-4' />,
		name: 'C#',
	},
];

// Resume link (string - "https://YourResumeUrl") - I am using CloudFront to share my resume (https://docs.aws.amazon.com/AmazonCloudFront/latest/DeveloperGuide/Introduction.html)
export const resume = null;

/* Projects
 ************************************************************** 
  List the repo names (string - "your-repo-name") you want to include (they will be sorted alphabetically). If empty, only the first 3 will be included.
*/
export const filteredProjects = ['bug-eye', 'conwaysGame', 'hot-dog', 'jamesOfReno', 'pinon-tree-business'];

// Replace the default GitHub image for matching repos below (images imported above - lines 13-14)
export const projectCardImages = [
	{
		name: 'hot-dog',
		displayName: 'Pet Friendly Social Media',
		image: hotDog,
		index: 0,
	},
	{
		name: 'bug-eye',
		displayName: 'Issue Tracking Made Easy',
		image: bugEye,
		index: 0,
	},
	{
		name: 'pinon-tree-business',
		displayName: 'Pinon Tree Services',
		image: pinonTree,
		index: 1,
	},
	{
		name: 'jamesOfReno',
		displayName: 'James of Reno',
		image: jamesOfReno,
		index: 1,
	},
	{
		name: 'conwaysGame',
		displayName: "P5.js Conway's Game",
		image: conwaysGame,
		index: 2,
	},
];

/* Contact Info
 ************************************************************** 
  Add your formspree endpoint below.
  https://formspree.io/
*/
export const formspreeUrl = 'https://formspree.io/f/xrgdgrro';

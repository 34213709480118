import React from 'react';
import ReactDOM from 'react-dom/client';
// https://reactjs.org/docs/context.html
import { AppProvider } from './appContext';
// https://redux.js.org/tutorials/fundamentals/part-5-ui-react#passing-the-store-with-provider
import { Provider } from 'react-redux';
import { store } from './store';
// https://create-react-app.dev/docs/adding-bootstrap
import 'bootstrap/dist/css/bootstrap.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

// https://reactjs.org/blog/2022/03/08/react-18-upgrade-guide.html
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
	<Provider store={store}>
		<AppProvider>
			<App />
		</AppProvider>
	</Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
